import request from '@/utils/request'
// 登录
export function login(data) {
  return request({
    url: 'login',
    method: 'post',
    data: data
  })
}
// 课程分类
export function courseTypes(data) {
  return request({
    url: '/course/stu/courseTypes',
    method: 'get',
    data: data
  })
}
// 课程列表
export function courses(data) {
  return request({
    url: '/course/stu/courses',
    method: 'post',
    data: data
  })
}
// 热门课程
export function hostCourse(data) {
  return request({
    url: '/course/stu/courses/top',
    method: 'get',
    data: data
  })
}
// 验证码
export function sendSms(data) {
  console.log(data)
  return request({
    url: '/register/sendSms' + '/' + data,
    method: 'get'
  })
}
