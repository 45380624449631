<template>
  <div class="details">
    <header-view></header-view>
    <div class="content">
      <div class="crumbs">
        <div class="crumbs-item">全部课程</div>
        <div class="crumbs-line">/</div>
        <div class="crumbs-item">取证课程</div>
        <div class="crumbs-line">/</div>
        <div class="crumbs-item">内部培训</div>
      </div>
      <div class="content-box">
        <div class="left">
          <video
            id="videobox"
            class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
            controls
            preload="auto"
            webkit-playsinline="true"
            playsinline="true"
            type="application/x-mpegURL"
            allowsInlineMediaPlayback="YES"
            webview.allowsInlineMediaPlayback="YES"
            oncontextmenu="return false;"
            width="100%"
            ref="videoRef"
            x5-video-player-fullscreen="true"
            v-show="videoShow"
            @ended="saveAddProcessTime"
          >
            <!-- <source id="sourceBox" src="/static/1600739005640_8758d12d9814437e96103f312cd37b5e.mp4" type="video/mp4"> -->
            <source id="sourceBox" :src="videoSrc" type="video/mp4" />
            <p class="vjs-no-js">不支持播放</p>
          </video>
          <div class="head">
            <div class="head-title">{{ detailInfo.title }}</div>
          </div>
          <div class="head" v-if="detailInfo.buyStatus == '1'">
            <div class="head-title"><el-progress :text-inside="true" :stroke-width="24" :percentage="progress" status="success"></el-progress></div>
            <p style="margin-top: 7px;">目前已完成 {{videoList[0].lessonAlreadyCount}} 个课时, 共计 {{videoList[0].lessonTotalCount}} 个课时。加油啊</p>
          </div>
          <div class="buy" v-if="detailInfo.buyStatus == 0">
            <div class="item">
              <div class="left">
                <img
                  class="img1"
                  src="../../assets/images/bootcamp-course.png"
                  alt=""
                />
                <div>课程培训</div>
              </div>
              <img
                class="img2"
                src="../../assets/images/bootcamp-plus.png"
                alt=""
              />
            </div>
            <div class="item">
              <div class="left">
                <img
                  class="img1"
                  src="../../assets/images/bootcamp-env.png"
                  alt=""
                />
                <div>课程资料</div>
              </div>
              <img
                class="img2"
                src="../../assets/images/bootcamp-plus.png"
                alt=""
              />
            </div>
            <div class="item">
              <div class="left">
                <img
                  class="img1"
                  src="../../assets/images/bootcamp-qa.png"
                  alt=""
                />
                <div>取证考试</div>
              </div>
              <img
                class="img2"
                src="../../assets/images/bootcamp-equal.png"
                alt=""
              />
            </div>
            <div class="items">
              <div class="prc">
                {{ detailInfo.isFree == 0 ? "￥" + detailInfo.price : "免费" }}
              </div>
              <div class="btn" @click="changeBuy">立即购买</div>
            </div>
          </div>
          <div class="details-c">
            <div class="nav">
              <div class="nav-left">
                <div
                  class="nav-item"
                  :class="{ 'nav-item-active': tabIndex == 1 }"
                  @click="selectTab(1)"
                >
                  课程详情
                </div>
                <div
                  class="nav-item"
                  :class="{ 'nav-item-active': tabIndex == 2 }"
                  @click="selectTab(2)"
                >
                  课程列表
                </div>
                <div
                  class="nav-item"
                  :class="{ 'nav-item-active': tabIndex == 3 }"
                  @click="selectTab(3)"
                >
                  相关资料
                </div>
              </div>
            </div>
            <!-- 课程详情 -->
            <div class="substance" v-if="tabIndex == 1">
              <div class="substance-title" v-html="detailInfo.content"></div>
            </div>
            <!-- 课程列表 -->
            <div class="course" v-if="tabIndex == 2">
              <div class="list">
                <el-collapse accordion v-model="activeName">
                  <el-collapse-item
                    v-for="(item, index) in videoList"
                    :key="index"
                    :name="index"
                  >
                    <template #title>
                      <div class="live">
                        <div class="name">{{ item.chapterTitle }}</div>
                      </div>
                    </template>
                    <div class="item-box">
                      <div
                        class="item"
                        v-for="(v, k) in item.lessonList"
                        :key="k"
                      >
                        <div class="num">
                          {{ k < 9 ? "0" + (k + 1) : k + 1 }}
                        </div>
                        <div class="name">{{ v.title }}</div>
                        <div class="btn4" v-if="lessonId == v.id && index != -1">正在播放</div>
                        <div
                          class="btn2"
                          v-if="v.lookStatus == 1 && detailInfo.buyStatus != 0 && v.complete == 0"
                          @click="changePlay(v)"
                        >
                          学习
                        </div>
                        <div
                          class="btn2"
                          v-if="v.lookStatus == 1 && detailInfo.buyStatus != 0 && v.complete == 1"
                          @click="changePlay(v)"
                        >
                          重新学习
                        </div>
                        <div
                          class="btn3"
                          v-if="
                            v.lookStatus == 1 &&
                            v.hasPaper == 1 &&
                            detailInfo.buyStatus != 0&& v.examStatus ==0
                          "
                          @click="changeExam(v)"
                        >
                          考试
                        </div>
                        <div
                          class="btn4"
                          v-if="
                            v.lookStatus == 1 &&
                            v.hasPaper == 1 &&
                            detailInfo.buyStatus != 0&& v.examStatus ==2
                          "
                          @click="changeExam(v)"
                        >
                          通过
                        </div>
                        <div
                          class="btn3"
                          v-if="
                            v.lookStatus == 1 &&
                            v.hasPaper == 1 &&
                            detailInfo.buyStatus != 0&& v.examStatus ==1
                          "
                          @click="changeExam(v)"
                        >
                          未通过
                        </div>
                        <div class="icon" v-if="v.lookStatus != 1">
                          <img src="../../assets/images/nopic.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
            <div class="ziliao" v-if="tabIndex == 3">
              <div class="list" v-for="(item, index) in docsList" :key="index">
                <div class="list_type">资料</div>
                <div class="list_text">
                  {{ item.title }}
                </div>
                <span class="list_big">{{ item.fileSize }}</span>
                <span class="list_num">{{ item.downloadCount }}人次下载</span>
                <img
                  @click="changeDow(item)"
                  src="../../assets/images/dow_active.png"
                  class="list_xia"
                  alt
                />
              </div>
            </div>
          </div>
          <div class="head">
            <div v-if="detailInfo.isExamLink == '1' && detailInfo.buyStatus == '1'" class="head-title">
              <el-link  :href="detailInfo.examLink" target="_blank">考试已开放，点击此处前往考试</el-link>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="heat">
            <div class="heat-title">最热课程</div>
            <div class="heat-list">
              <div
                class="list-item"
                v-for="(item, index) in hostList"
                :key="index"
              >
                <img :src="item.icon" alt="" />
                <div class="txt">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="code">
            <div class="code-img">
              <img src="../../assets/images/gong.png" alt="" />
            </div>
            <div class="code-txt">关注公众号</div>
          </div>
        </div>
      </div>
    </div>
    <footer-view></footer-view>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialog"
      :before-close="cancel"
      width="80%"
    >
      <div class="buy">
        <div
          class="buy-item"
          @click="selectBuy(item.dictValue)"
          :class="{ 'buy-active': buyIndex == item.dictValue }"
          v-for="(item, index) in payType"
          :key="index"
        >
          {{ item.remark }}
        </div>
      </div>
      <div class="buycen">
        <div class="buycen-wx" v-if="buyIndex == 1">
          <div id="qrcode" ref="qrcode"></div>
          <div class="btn-wx" @click="changeFinish">我已支付完成</div>
        </div>
        <div class="buycen-wx" v-if="buyIndex == 2">
          <div class="buycen-title">点击下方确认按钮，前往支付宝收银台</div>
        </div>
        <div class="buycen-dui" v-if="buyIndex == 3">
          <el-input
            placeholder="请输入兑换码"
            v-model="exchangeCode"
          ></el-input>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="price">
          {{ detailInfo.isFree == 0 ? "￥" + detailInfo.price : "免费" }}
        </div>
        <div class="btn" @click="submit">确定</div>
        <!-- <el-button @click="cancel">取消</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import {
  content,
  detail,
  payTypes,
  pay,
  queryOrder,
  studyRecord,
  courseDocs,
  courseCheck,
  download,
} from "@/api/details";
import { hostCourse } from "@/api/list";
import videojs from "video.js";
import QRCode from "qrcodejs2";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      tabIndex: 1,
      id: this.$route.query.id,
      videoList: "",
      videoSrc: "",
      videoShow: false,
      detailInfo: {
        title: "",
      },
      hostList: "",
      dialog: false,
      dialogTitle: "支付方式",
      payType: [],
      buyIndex: "",
      exchangeCode: "",
      orderNum: "",
      shijian: "",
      timer: "",
      lessonId: "",
      activeName: 0,
      docsList: "",
      lastStudyTime: "",
      progress: 0,
    };
  },
  mounted() {
    this.getlist();
    this.getDetail();
    this.getHost();
    this.getcourseDocs();
  },
  destroyed: function () {
    if (this.shijian != 0) {
      this.changeRecord();
    }
    clearInterval(this.timer); // 清除定时器
    this.timer = null;
  },
  methods: {
    // 资料下载
    changeDow(item) {
      console.log(item);
      courseCheck(item.id)
        .then((res) => {
          console.log("下载", res);
          if (res.data.status == 1) {
            download(item.id)
              .then((res) => {})
              .catch(() => {});
            window.open(item.fileUrl, "_blank");
          } else {
            this.$confirm("暂无权限下载本文件！", "提示", {
              confirmButtonText: "确定",
              type: "warning",
              showCancelButton: false,
            }).then(() => {});
          }
        })
        .catch(() => {});
    },
    // 获取支付方式
    changeBuy() {
      if (this.detailInfo.isFree == 0) {
        payTypes()
          .then((res) => {
            console.log(res);
            if (this.detailInfo.isFree == 0) {
              this.payType = res.data;
              this.buyIndex = res.data[0].dictValue;
              if (res.data[0].dictValue == 1) {
                this.changeGetpay();
              }
            }
            this.dialog = true;
          })
          .catch(() => {});
      } else {
        pay({
          courseId: this.id,
          exchangeCode: this.exchangeCode,
          payType: 4,
        })
          .then((res) => {
            console.log(res);
            if (res.data.success == "true") {
              this.$message({
                message: "开通成功！",
                type: "success",
              });
              this.dialog = false;
              this.getlist();
              this.getDetail();
            }
          })
          .catch(() => {});
      }
    },
    // 查看支付结果
    changeFinish() {
      queryOrder(this.orderNum)
        .then((res) => {
          console.log(typeof res.data.success);
          if (res.data.success == "true") {
            this.$message({
              message: "支付完成！",
              type: "success",
            });
            this.dialog = false;
            this.getlist();
            this.getDetail();
          } else {
            this.$message({
              message: "支付未完成！",
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    // 微信支付
    changeGetpay() {
      pay({
        courseId: this.id,
        exchangeCode: this.exchangeCode,
        payType: this.buyIndex,
      })
        .then((res) => {
          console.log(res);
          if (this.buyIndex == 1) {
            let qrcode = new QRCode("qrcode", {
              width: 150, // 二维码宽度
              height: 150, // 二维码高度
              text: res.data.code_url,
            });
            this.orderNum = res.data.orderNum;
          }
        })
        .catch(() => {});
    },
    // 确定支付
    submit() {
      if (this.buyIndex == 1) {
        this.changeFinish();
      } else {
        pay({
          courseId: this.id,
          exchangeCode: this.exchangeCode,
          payType: this.buyIndex,
        })
          .then((res) => {
            console.log(res);
            if (this.buyIndex == 2) {
              document.querySelector("body").innerHTML = res.data.body;
              const div = document.createElement("div"); // 创建div
              div.innerHTML = res.data.body; // 将返回的form 放入div
              document.body.appendChild(div);
              document.forms[0].submit();
            }
            if (this.buyIndex == 3) {
              if (res.data.success == "true") {
                this.$message({
                  message: "兑换成功！",
                  type: "success",
                });
                this.dialog = false;
                this.getlist();
                this.getDetail();
              } else {
                this.$message({
                  message: "兑换码不正确！",
                  type: "error",
                });
              }
            }
            if (this.buyIndex == 4) {
              if (res.data.success == "true") {
                this.$message({
                  message: "开通成功！",
                  type: "success",
                });
                this.dialog = false;
                this.getlist();
                this.getDetail();
              }
            }
          })
          .catch(() => {});
      }
    },
    selectBuy(index) {
      this.buyIndex = index;

      if (this.buyIndex == 1) {
        this.changeGetpay();
      } else {
        const codeHtml = document.getElementById("qrcode");
        codeHtml.innerHTML = "";
      }
    },
    cancel() {
      this.dialog = false;
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
    },
    selectTab(index) {
      this.tabIndex = index;
    },
    getHost() {
      hostCourse()
        .then((res) => {
          console.log(res);
          this.hostList = res.data;
        })
        .catch(() => {});
    },
    goDetails(id) {
      this.id = id;
      this.getlist();
      this.getDetail();
    },
    // 课程目录
    getlist() {
      content(this.id)
        .then((res) => {
          this.videoList = res.data;
          this.progress = this.videoList[0].lessonAlreadyCount/this.videoList[0].lessonTotalCount;
          console.log(this.progress);
          if(this.progress < 1){
            this.progress = 1;
          }
        })
        .catch(() => {});
    },
    // 课程列表
    getDetail() {
      detail(this.id)
        .then((res) => {
          console.log(res);
          this.detailInfo = res.data;
        })
        .catch(() => {});
    },
    // 课程列表
    getcourseDocs() {
      courseDocs(this.id)
        .then((res) => {
          console.log("资料+", res);
          this.docsList = res.data;
          // this.detailInfo = res.data;
        })
        .catch(() => {});
    },
    changeRecord() {
      console.log(this.shijian);
      studyRecord({
        lessonId: this.lessonId,
        maxDuration: Math.floor(this.shijian),
        studyDuration: Math.floor(this.shijian),
      })
        .then((res) => {
          console.log(res);
          this.getlist();
        })
        .catch(() => {});
    },
    changeExam(v) {
      this.$router.push({
        path: `Exam`,
        query: {
          id: v.paperId,
        },
      });
    },
    changePlay(v,k) {
      let _this = this;
      if (this.videoSrc == "") {
        this.videoSrc = v.videoUrl;
        this.lessonId = v.id;
        this.lastStudyTime = v.lastStudyTime;
        this.videoShow = true;
        this.initVideo(0);
        this.videoploy();
        setTimeout(function () {
          _this.changeTime();
        }, 100);
      } else {
        this.changeRecord();
        this.videoShow = false;
        clearInterval(this.timer); // 清除定时器
        this.timer = null;
        this.videosrc = v.videoUrl;
        this.lessonId = v.id;
        this.lastStudyTime = v.lastStudyTime;
        this.videoShow = true;
        var player = videojs("videobox");
        player.src({
          src: this.videosrc,
        });
        setTimeout(function () {
          _this.changeTime();
        }, 100);
      }
    },
    changeTime() {
      let _this = this;
      if (document.getElementById("videobox")) {
        console.log(this.videoSrc);

        let myVideo = videojs("videobox");
        myVideo.cache_.currentTime = _this.lastStudyTime;
        let stop = true;
        let abc = 0;
        setTimeout(function () {
          abc = myVideo.cache_.duration;
          console.log("视频总时长" + abc);
        }, 1000);
        this.timer = setInterval(() => {
          let time = myVideo.cache_.currentTime;
          this.shijian = time;
          console.log(this.shijian, "时间点");
          if ((this.shijian / abc) * 100 > 96 && stop) {
            console.log("本节已看完");
            _this.changeRecord();
            stop = false;
          }
        }, 1000);
      }
    },
    saveAddProcessTime() {
      this.changeRecord();
      clearInterval(this.timer); // 清除定时器
      this.timer = null;
    },
    initVideo(startTime) {
      //此处初始化的调用，我放在了获取数据之后的方法内，而不是放在钩子函数mounted
      //页面dom元素渲染完毕，执行回调里面的方法
      this.$nextTick(() => {
        let myPlayer = this.$video(videobox, {
          //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
          controls: true,
          //自动播放属性,muted:静音播放
          autoplay: false,
          //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
          preload: "auto",
          //设置视频播放器的显示宽度（以像素为单位）
          // width: "800px",
          //设置视频播放器的显示高度（以像素为单位）
          // height: "400px",
          controlBar: {
            playToggle: true,
          },
        });
      });
    },
    videoploy() {
      var that = this;
      var vd = document.getElementById("videobox"); //获取video对象
      console.log(that.lastStudyTime);
      if (that.lastStudyTime) {
        console.log("继续播放");
        vd.play();
        vd.currentTime = that.lastStudyTime;
        that.shijian = that.lastStudyTime;
      } else {
        vd.addEventListener("play", function () {
          console.log("开始播放");
          vd.currentTime = that.shijian;
        });
      }
      vd.addEventListener("seeking", function () {
        if (vd.currentTime > that.shijian) {
          vd.currentTime = that.shijian;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .content {
    width: 100% !important;
  }
  .right {
    display: none;
  }
  .content .left .details .course .course-item {
    margin-right: 0 !important;
  }
  .content .left .details .course {
    flex-direction: column;
    align-items: center;
  }
  .content .left .tab .tab-item .tab-list {
    flex-wrap: wrap;
  }
  .content .left .tab .tab-item .tab-list .tabs {
    flex-shrink: 0;
  }
  .content .left .tab .tab-item .tab-title {
    flex-shrink: 0;
  }
  .content .content-box .left .details-c .nav .nav-left .nav-item {
    margin-right: 20px !important;
  }
  .content .content-box {
    display: block;
  }
  .content .content-box .left .buy {
    flex-direction: column;
    height: 100% !important;
  }
  .content .content-box .left .buy .item {
    flex-direction: column;
    width: 100% !important;
  }
  .content .content-box .left {
    width: 100% !important;
  }
  .content .content-box {
    display: block !important;
  }
  .content .content-box .left .buy .item .img2 {
    margin: 20px 0 !important;
  }
}
.details {
  background: #f9f9f9;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #e5e5e5;
  .price {
    color: #ff712a;
    font-size: 24px;
  }
  .btn {
    color: #fff;
    background-color: #0c9;
    border-color: #0c9;
    padding: 6px 20px;
    font-size: 14px;
    cursor: pointer;
  }
}
.buy {
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding-top: 10px;
  .buy-item {
    width: 120px;
    height: 46px;
    margin-right: 10px;
    border: 1px dashed #d2d2d2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buy-active {
    border: 2px solid #f55f60;
    color: #f55f60;
  }
}
.buycen {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .buycen-wx {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-wx {
      color: #fff;
      background-color: #0c9;
      border-color: #0c9;
      padding: 6px 20px;
      font-size: 14px;
      cursor: pointer;
      margin-top: 10px;
    }
  }
  .buycen-title {
    font-size: 20px;
  }
}
.content {
  width: 1170px;
  margin-right: auto;
  margin-left: auto;

  padding-top: 20px;
  box-sizing: border-box;
  min-height: calc(100vh - 100px);
  .crumbs {
    padding: 10px 0;
    display: flex;
    align-items: center;
    margin-left: 5px;
    .crumbs-item {
      font-size: 13px;
      color: #666;
      cursor: pointer;
      &:hover {
        color: #0c9;
      }
    }
    .crumbs-line {
      color: #666;
      font-size: 13px;
      margin: 0 10px;
    }
  }
  .content-box {
    display: flex;
    justify-content: space-between;
    .left {
      width: 867px;

      .video {
        width: 100%;
      }
      .head {
        padding: 30px;
        background: #fff;
        border: 1px solid #eee;
        box-sizing: border-box;
        .head-title {
          font-size: 21px;
          font-weight: 500;
          color: #333;
        }
        .head-c {
          color: #4c5157;
          font-size: 15px;
          margin: 16px 0;
        }
      }
      .buy {
        background: #f5fffc;
        border-top: 1px solid #ecf0f3;
        overflow: hidden;
        height: 155px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .item {
          width: 25%;
          display: flex;
          align-items: center;
          .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            .img1 {
              width: 48px;
              height: 40px;
              display: block;
            }
          }

          .img2 {
            height: 14px;
            width: 14px;
          }
        }
        .items {
          width: 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 155px;
          .prc {
            font-size: 24px;
            color: #ff712a;
          }
          .btn {
            padding: 4px 20px;
            color: #fff;
            background: #ff712a;
            font-size: 24px;
            cursor: pointer;
            margin-top: 20px;
          }
        }
      }
      .details-c {
        margin-bottom: 10px;
        padding: 30px;
        background: #fff;
        border: 1px solid #eee;
        box-sizing: border-box;
        margin-top: 20px;
        .nav {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #eee;
          margin-bottom: 10px;
          align-items: flex-end;

          .nav-left {
            display: flex;
            .nav-item {
              color: #4c5157;
              background-color: transparent;
              border: none;
              border-bottom: 1px solid transparent;
              font-size: 16px;
              padding-bottom: 10px;
              cursor: pointer;
              margin-right: 48px;
            }
            .nav-item-active {
              border-bottom-color: #0c9;
            }
          }
        }
        .substance {
          .substance-title {
            color: #4c5157;
            font-size: 15px;
          }
        }
        .course {
          .list {
            .live {
              width: 100%;
              height: 50px;
              // background: #3c424d;
              display: flex;
              align-items: center;
              padding: 0 1%;
              box-sizing: border-box;
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);
              .img {
                height: 20px;
                width: 20px;
              }
              .name {
                font-size: 14px;
                // color: #ffffff;
                margin-left: 15px;
              }
              .arrow {
                margin-left: auto;
                height: 10px;
                width: 18px;
              }
            }
            .item-box {
              .item {
                display: flex;
                align-items: center;
                height: 50px;
                // background: #3c424d;
                padding: 0 1%;
                padding-left: 5%;
                box-sizing: border-box;
                margin-bottom: 1px;
                width: 100%;
                border-bottom: 1px solid #eee;
                &:last-child {
                  border-bottom: 0;
                }
                .num {
                  font-size: 13px;
                  // color: #ffffff;
                  margin-right: 16px;
                }
                .name {
                  font-size: 13px;
                  // color: #ffffff;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .btn2 {
                  height: 24px;
                  border: 1px solid #ff6923;
                  border-radius: 4px;
                  font-size: 12px;
                  font-weight: bold;
                  color: #ff6923;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: auto;
                  cursor: pointer;
                  padding: 0 14px;
                  box-sizing: border-box;
                }
                .btn3 {
                  height: 24px;
                  background: #ff6923;
                  border-radius: 4px;
                  font-size: 12px;
                  font-weight: bold;
                  color: #ffffff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  margin-left: 10px;
                  padding: 0 14px;
                  box-sizing: border-box;
                }
                .btn4 {
                  height: 24px;
                  background: #11aa8c;
                  border-radius: 4px;
                  font-size: 12px;
                  font-weight: bold;
                  color: #ffffff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  margin-left: 10px;
                  padding: 0 14px;
                  box-sizing: border-box;
                }
                .icon {
                  margin-left: auto;
                  margin-right: 20px;
                }
              }
            }
          }
        }
        .ziliao {
          .list {
            display: flex;
            align-items: center;
            padding: 20px 15px;
            border-bottom: 1px solid #eee;
            .list_type {
              background: #008aff;
              color: #fff;
              text-align: center;
              width: 48px;
              height: 24px;
              line-height: 24px;
              font-size: 14px;
              border-radius: 4px;
              margin-right: 30px;
            }
            .list_text {
              font-size: 14px;
              font-weight: bold;
            }
            .list_big,
            .list_num {
              color: #646464;
              font-weight: 500;
              line-height: 42px;
              margin-left: auto;
              margin-right: 40px;
            }
            .list_xia {
              width: 26px;
              height: 20px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .right {
      width: 280px;
      .heat {
        margin-bottom: 10px;
        padding: 15px;
        background: #fff;
        border: 1px solid #eee;
        .heat-title {
          padding: 0 0 10px;
          border-bottom: 1px solid #eee;
          font-size: 16px;
          color: #4c5157;
          display: flex;
          justify-content: flex-start;
        }
        .heat-list {
          .list-item {
            padding: 10px;
            word-break: break-all;
            word-wrap: break-word;
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              height: 25px;
              width: 25px;
            }
            .txt {
              color: #565a61;
              font-size: 14px;
              margin-left: 5px;
            }
          }
        }
      }
      .code {
        padding: 15px 0;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .code-img {
          height: 183px;
          width: 183px;
        }
        .code-txt {
          color: #4c5157;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
